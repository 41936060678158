.view-clubs-information-page{
    .view-clubs-info-section{
        .page-details{
            padding: 29px 20px;
            padding-bottom: 100px;
            .club-item{
                .img-wrapper{
                    height: 137px;
                    border-radius: 10px; 
                    overflow: hidden;                   
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .club-name{
                    font-size: 15px;
                    color: var(--dark-gray);
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-decoration: none;
                    min-height: 43px;
                }
            }
            .affiliated-clubs{
                color: var(--dark-gray);
                font-size: 16px;
                span.required{
                    color: var(--sandy-brown);
                }
                #react-select-3-placeholder{
                    font-size: 15px;
                    color: var(--medium-gray);
                }
                .select__value-container{
                    padding-left: 30px;
                }
                

                .search-box{
                    position: relative;
                    .search-icon{
                        position: absolute;
                        z-index: 10;
                        stroke:  var(--dark-gray);
                        top: 22%;
                        left: 2%;
                        path{
                            fill:  var(--dark-gray);
                        }
                    }
                }
                .club-list{
                    .item{
                        padding: 14px 23px;
                        padding-right: 10px;
                        border: 1px solid var(--light-grayish);
                        border-radius: 14px;
                        background: var(--grayish-blue);
                        .left{
                            .title{
                                color: var(--dark-shade-gray);
                                font-size: 17px;
                                font-weight: 500;
                            }
                            .location{
                                position: relative;
                                color: var(--dark-shade-gray);
                                font-size: 15px;
                                padding-left: 15px;
                                &::before{
                                    content: url("../../images/common/location.svg");
                                    position: absolute;
                                    left: 0%;
                                }
                            }
                        }
                        .right{
                            div{
                                cursor: pointer;
                                width: 26px;
                                height: 26px;
                                background: var(--white);
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}