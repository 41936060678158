.view-survey-questioner-page{
    .view-survey-questioner{
        label{
            color: var(--steel-blue-gray);
        }
        input{
            border: none;
            background-color: white;
            color: var(--charcol-gray);
            padding-left: 0px;
        }
        .question-list {
            .question-item{
                textarea{
                    border: 1px dashed var(--slight-blue-tint);
                    &:focus{
                        box-shadow: none;
                    }
                }
                .q-number, .question{
                    color: var(--black);
                    font-size: 15px;
                }
                .options-list{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 5px;
                }
            }
        }
    }
}