.create-survey-questioner{
    .select__control.select__control--is-focused {
        border-color: var(--light-grayish);
        box-shadow: 0 0 0 1px var(--light-grayish);
    }
    .questioner-section{
        .item{
            border-radius: 5px;
            padding: 19px 25px;
            background-color: var(--nuetral-light-gray);
            .options-list{
                .MuiButtonBase-root.MuiRadio-root{
                    padding-left: 0px;
                }
                input{
                    border: 1px solid var(--slight-blue-tint);
                }
                label{
                    font-size: 14px;
                    color: var(--gray-olive);
                }
                .delete-option-icon{
                    background-color: var(--white);
                    padding: 4px 6px;
                    border-radius: 3px;
                    margin-left: 4px;
                }
            }
            .question-number{
                color: var(--black);
                font-size: 16px;
            }
            textarea, input{
                color: var(--charcol-gray) !important;
                &::placeholder{
                    color: var(--charcol-gray) !important;
                }
            }
            textarea{
                border: 1px dashed var(--light-grayish);
                border-radius: 5px;
                &:focus{
                    box-shadow: none;
                }
            }
            .answer-box{
                padding-left: 31px;
            }
            .delete-icon{
                background-color: var(--white);
                border-radius: 5px;
                padding: 6px;
                color: var(--dark-charcol);
            }            
        }
    }
}