.create-clubs-info-section {
    .page-details {
        background-color: var(--white);
        padding: 29px 20px;
    }

    .club-profile-section {
        .ck.ck-editor__editable_inline {
            ::-webkit-scrollbar-track {
                background: var(--soft-steel-blue) !important;
            }

            ::-webkit-scrollbar-thumb {
                background-color: var(--soft-steel-blue) !important;
            }
        }

        .ck-content.ck-editor__editable {
            min-height: 300px !important;
            max-height: 300px !important;
            overflow-y: scroll;

            ul {
                li {
                    margin-left: 34px !important;
                }
            }
        }

        .club-form,
        .gallery-details {
            label {
                color: var(--dark-gray);
            }

            .add-contact {
                color: var(--sky-blue);
            }

            .item.upload {

                .upload-icon {
                    border: 1px solid var(--light-grayish);
                    border-radius: 10px;
                    padding: 22px 35px;

                    div {
                        color: var(--medium-gray);
                        font-size: 15px;
                        text-align: center;
                    }
                }

                .info {
                    font-size: 12px;
                    margin-left: 10px;
                    color: var(--dark-shade-gray);
                }

                .uploaded-images {
                    width: 130px;
                    height: 130px;
                    padding: 10px;
                    border: 1px solid var(--light-grayish);
                    border-radius: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .uploaded-images.active {
                    .pos-rel {
                        position: relative;

                        .close-btn {
                            display: flex;
                            justify-content: center;
                            align-items: start;
                            position: absolute;
                            top: -24%;
                            right: -23%;
                            background: rgb(112, 112, 112);
                            border: none;
                            color: rgb(255, 255, 255);
                            border-radius: 50%;
                            width: 18px;
                            height: 18px;
                            cursor: pointer;
                            font-size: 13px;
                            line-height: 14.5px;
                            text-align: center;
                        }
                    }
                }

                .filename {
                    margin-top: 2px;
                    font-size: 12px;
                    max-width: 130px;

                    .name {
                        max-width: 105px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    svg {
                        cursor: pointer;
                        min-width: 16px;
                        min-height: 16px;
                    }
                }
            }

            input,
            textarea {
                border: 1px solid var(--light-grayish);
                outline: none;
                color: var(--medium-gray);
                font-size: 15px;

                &:focus {
                    box-shadow: none;
                }

                &::placeholder {
                    color: var(--medium-gray);
                    font-size: 15px;
                }
            }
        }

        .about-club-section {
            .location-map {
                position: relative;

                .search-loc-box {
                    position: absolute;
                    width: 325px;
                    border-radius: 6px;
                    top: 10%;
                    transform: translate(-50%);
                    left: 50%;
                    background: var(--dark-gray);
                    padding: 9px 29px;

                    &::before {
                        content: url("../../images/common/search-icon.svg");
                        position: absolute;
                        left: 0%;
                        top: 26%;
                        margin-left: 8px;
                    }

                    input {
                        border: none;
                        outline: none;
                        background-color: transparent;
                        color: var(--white);

                        &:focus {
                            box-shadow: none;
                        }

                        &::placeholder {
                            color: var(--white);
                        }
                    }
                }
            }
        }

        .gallery-details {
            label {
                font-size: 16px;
            }

            .video-input {
                border-right: none;
                border-radius: 5px 0px 0px 5px;
            }

            .upload-video {
                background-color: var(--light-grayish-blue);
                border: 1px solid var(--light-grayish);
                padding: 6.53px 10px;
                color: var(--dark-gray);
                font-size: 15px;
                border-radius: 0px 5px 5px 0px;

                svg {
                    stroke: var(--dark-gray);
                    margin-right: 10px;

                    g {
                        stroke: var(--dark-gray);
                    }
                }
            }

            .multiple-images-upload {
                border: 1px solid var(--light-grayish);
                border-radius: 5px;

                .top {
                    padding: 22px 16px;
                    font-size: 16px;
                    color: var(--dark-gray);
                    border-bottom: 1px solid var(--light-grayish);
                }

                .bottom {
                    padding: 16px;

                    .drag-box {
                        border: 1px dashed var(--medium-gray-type-1);
                        border-radius: 5px;

                        .dropzone {
                            padding: 30px 15px;

                            &:focus-visible {
                                outline: none;
                            }
                        }

                        aside {
                            padding: 30px 15px;
                            padding-top: 0px;
                        }

                        p {
                            text-align: center;
                        }
                    }
                }
            }

            .info-msg {
                color: var(--soft-gray-type-1);
                font-size: 12px;
            }
        }
    }
}

.booking-overview1 {
    position: relative;

    .booking-overview-club {
        min-height: 350px;
        background-color: var(--white);
        border-radius: 0 0 15px 15px;

        .img-wraper {
            width: 100%;
            position: relative;
            display: inline-block;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tags-container {
            position: absolute;
            top: 10px;
            left:10px;
            display: flex;
            gap: 10px;
          }
    
        .tag {
            padding: 5px 10px;
            text-align: center;
            color: white;
            font-size: 15px;
            border-radius: 4px;
            width: 100px
          }
          
          .reciprocal-tag {
            background-color: #f3f6f9;
         
            color:black;
          }
          
          .solitaire-tag {
            background-color: var(--sandy-brown);
           
           
          }
          
         

        .club-name-wraper {
            padding: 20px 24px 0 20px;

            .club-name-sec {
                padding-left: 20px;
                border-left: 5px solid var(--sandy-brown);

                .club_name_title {
                    font-size: 17px;
                    margin: 0;
                }

                .club_name_main {
                    font-size: 20px;
                    color: var(--sandy-brown);
                    cursor: pointer;
                }
            }
        }
    }
}

.booking-overview {
    .booking-overview-club {
        min-height: 350px;
        background-color: var(--white);
        border-radius: 0 0 15px 15px;

        .img-wraper {
            width: 100%;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .club-name-wraper {
            padding: 20px 24px 0 20px;

            .club-name-sec {
                padding-left: 20px;
                border-left: 5px solid var(--sandy-brown);

                .club_name_title {
                    font-size: 17px;
                    margin: 0;
                }

                .club_name_main {
                    font-size: 20px;
                    color: var(--sandy-brown);
                    cursor: pointer;
                }
            }
        }
    }
}

.booking_stats {
    margin-top: 15px;
    // height: 280px;
    background: rgb(0, 0, 4);
    background: linear-gradient(136deg, rgba(0, 0, 4, 1) 0%, rgba(78, 33, 1, 1) 78%, rgba(10, 5, 1, 1) 100%);
    border-radius: 10px;
    padding: 24px;
    color: var(--white);

    .booking_date {
        font-size: 20px;
        color: var(--white);
    }
}

.booking_divider {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px dashed white;
}

@media screen and (max-width: 1400px) {
    .booking_stats {
        height: fit-content;

        .booking_stats_inside {
            flex-direction: column;
            text-align: center;
        }

        .booking_date {
            .date_view {
                flex-direction: column;
            }
        }
    }

    .booking_status_inside {
        text-align: center;
    }
}

.panel-type-1a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-blue);
    padding: 12px 16px;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.panel-type-1a:hover {
    background-color: #e9ecef;
}

.add_user_booking {
    background-color: var(--sandy-brown);
    border-radius: 5px;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Accordion icon */
.accordion-icon {
    font-size: 18px;
    transition: transform 0.3s ease-in-out;
}

/* Accordion content (collapsed by default) */
.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
}

/* When accordion is open */
.accordion-content.open {
    max-height: 1000px;
    /* Adjust as needed */
    opacity: 1;
    padding-top: 10px;
    overflow: visible;
    padding-bottom: 30px;
    padding-top: 20px;
}

.facility_wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 20px;
}

.facility_itm {
    width: 120px;
    height: 90px;
    // background-color: var(--sand-stone);
    border: 2px solid black;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .fac_name {
        margin: 0;
        padding-top: 2px;
        text-align: center;
        font-size: 16px;
    }
}

.payment-container {
    .custom-radio .form-check-input {
        width: 20px;
        height: 20px;
        border: 2px solid var(--sandy-brown);
        box-shadow: none;
        background-color: transparent;
        appearance: none;
        /* Removes default styles */
        transition: background-color 0.3s ease, border-color 0.3s ease;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
    }

    /* Create a filled effect when checked */
    .custom-radio .form-check-input:checked {
        border-color: var(--sandy-brown);
        background-color: var(--sandy-brown) !important;
    }

    /* Ensures inner circle is filled */
    .custom-radio .form-check-input:checked::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: white;
        /* Change if needed */
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}



.charges,
.total {
    font-weight: bold;
}


.view_message_header {
    font-size: 18px;
}

.message_container {
    min-height: 150px;
    background-color: var(--light-blue);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.message_list {
    max-height: 200px;
    /* Increased height for better visibility */
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #ccc transparent;
}

/* Scrollbar for Webkit (Chrome, Edge, Safari) */
.message_list::-webkit-scrollbar {
    width: 6px;
}

.message_list::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
}

.message_list::-webkit-scrollbar-track {
    background-color: transparent;
}

.message_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.message_wrapper.sent {
    justify-content: flex-end;
}

.message_wrapper.received {
    justify-content: flex-start;
}

.message {
    padding: 10px 14px;
    border-radius: 10px;
    max-width: 70%;
    font-size: 14px;
    word-wrap: break-word;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.message.sent {
    background-color: #007bff;
    color: white;
    text-align: right;
}

.message.received {
    background-color: #f1f1f1;
    color: black;
    text-align: left;
}



.message_input {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    background: white;
    border-radius: 8px;
}

@media (max-width: 600px) {
    .message {
        max-width: 85%;
        font-size: 13px;
    }

    .message_container {
        min-height: 120px;
    }

    .message_list {
        max-height: 150px;
    }
}


//   .message_container {
//     min-height: 150px;
//     background-color: var(--light-blue);
//     margin-bottom: 10px;
//     padding: 10px;
//     border-radius: 8px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//   }
//   .message_list {
//     max-height: 150px;
//     overflow-y: auto;
//     padding: 5px;
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//   }

//   .message_wrapper.bot {
//     display: flex;
//     justify-content: flex-start;
//   }

//   .message_wrapper.user {
//     display: flex;
//     justify-content: flex-end;
//   }

//   .message {
//     padding: 8px 12px;
//     border-radius: 10px;
//     max-width: 75%;
//     font-size: 14px;
//   }

//   .message.bot {
//     background-color: #f1f1f1;
//     color: black;
//   }

//   .message.club {
//     background-color: #007bff;
//     color: white;
//   }

//   .message_input {
//     display: flex;
//     gap: 10px;
//   }

.guest_decrement {
    width: 50px;
    height: 50px;
    padding: 10px;

    .decrement {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.guest_increment {
    width: 50px;
    height: 50px;
    padding: 10px;

    .increment {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.guest_count {
    .guest_count_input {
        max-width: 50px;
        text-align: center;
        border: none;
    }
}

.password-input-container {
    position: relative;
    width: 100%;
}

.password-input {
    width: 100%;
    padding-right: 40px;
    /* Space for the icon */
}

.view-password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #555;
}

.notes_section {
    margin-top: 15px;
    background-color: var(--white);
    min-height: 250px;
    border-radius: 10px;

    .head {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .body {
        padding: 10px;
        font-size: 16px;
    }
}