.notification-section{
    .title {
        font-size: 18px;
        .arrow{
            margin-top: -7px;
            cursor: pointer;
        }
    }
    .new-notifications{
        .subtitle{
            font-size: 15px;
            font-weight: 500;
        }
        .items-list{
            .item.border-none{
                border: none;
            }
            .item{
                padding-bottom: 10px;
                border-bottom: 1px solid var(--soft-gray);
                .icon-content{
                    cursor: pointer;
                }
                .icon{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background: var(--alabaster);
                    display: flex;
                    align-items: center;
                    justify-content: center;  
                    @media screen and (max-width: 576px) {
                        min-width: 37px;
                        min-height: 37px;                    
                        max-width: 37px;
                        max-height: 37px;                    
                    }
                }
                .content{
                    font-weight: 300;  
                    .top{
                        font-size: 16px;
                        span{
                            font-weight: 400;
                        }
                    } 
                    .bottom{
                        margin-top: 5px;
                        .sub-title{
                            font-weight: 400;
                        }
                       button{
                            color: var(--black);
                            border: 1px solid var(--soft-sand);
                            border-radius: 9px;
                            padding: 6px 15px;
                            text-align: center;
                            margin-right: 15px;
                            font-weight: 400;
                       }
                    }
                }
                .time{
                    font-weight: 300;
                    @media screen and (max-width: 576px) {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.notification-details-section{
    .create-clubs-info-section{
        .page-details{
            .important-instructions{
                color: var(--bs-blue);
                cursor: pointer;
            }
            .MuiBox-root{
                .MuiStepper-root {
                    .MuiStepLabel-root .MuiStepLabel-label{
                        font-family: 'HelveticaNeue';
                        font-size: 15px;
                        font-weight: 500;
                    }
                    .MuiCollapse-wrapperInner .MuiTypography-root{
                        font-size: 14px;
                        span{
                            font-family: 'HelveticaNeue';
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
