/* For WebKit-based browsers (e.g., Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 12px;
  /* Set the width of the scrollbar */
  height: 12px;
  /* Set the height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--light-gray);
  /* Scrollbar track color */
  border-radius: 0px;
  /* No rounding on corners */
}

::-webkit-scrollbar-thumb {
  background-color: var(--soft-steel-blue);
  /* Scrollbar thumb color */
  border-radius: 0px;
  /* No rounding on corners */
  border: 2px solid transparent;
  /* Optional: Add space around the thumb */
  background-clip: padding-box;
  /* Ensures thumb color is visible within borders */
}

// React prosidebar
.ps-sidebar-root {
  position: fixed !important;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding-top: 65px;
  background-color: var(--white);

  .ps-sidebar-container {
    background-color: var(--white);

    .ps-menu-button {
      .ps-menu-label {
        white-space: pre-wrap;
        font-size: 15px;
        position: relative;
        overflow: visible;

        .optional-menu {
          // display: none;
          font-size: 11px;
          padding: 1px 7px;
          background: var(--sandy-brown);
          color: var(--white);
          border-radius: 12px;
          position: absolute;
          right: 2%;
          top: 89%;
        }

        .optional-menu.curated {
          top: unset;
        }

        .optional-menu.promotion-optional {
          right: 8%;
        }
      }

      .ps-menu-label.ps-active {
        .optional-menu.promotion-optional {
          border: 1px solid var(--white);
        }
      }
    }

    .ps-submenu-content {
      .ps-menu-label {
        padding-left: 25px;
      }
    }
  }

  .ps-menuitem-root.cme-submenu {
    padding: 13px 0px;
    padding-bottom: 7px;
  }

  .ps-menuitem-root.cce-submenu {
    padding: 5px 0px;
  }

  .ps-menuitem-root.ps-active {
    background-color: var(--sandy-brown);

    &:hover {
      background-color: var(--sandy-brown) !important;
    }
  }

  .user-management-menu {
    .ps-menu-button .ps-menu-icon {
      svg path {
        stroke: var(--sandy-brown);
      }
    }
  }
}

.sidebar {
  position: relative;
  background-color: var(--white);
  padding: 20px 10px;
  width: 280px;
  z-index: 110;
  height: calc(100vh - 65px);
  overflow-y: scroll;
  //   scrollbar-width: thin;
  position: fixed;
  top: 65px;

  @media screen and (max-width: 1750px) {
    width: 250px;
  }

  .main {
    li.active {
      svg path {
        stroke: var(--white) !important;
      }

      a {
        background-color: var(--sandy-brown) !important;
        color: var(--white) !important;
        font-weight: 500;
        text-decoration: none;
      }
    }

    li.bookings-menu,
    li.notification-menu {
      a {
        position: relative;

        &::after {
          content: url("../../images/common/next-down-icon.svg");
          position: absolute;
          right: 5%;
          top: 28%;
        }
      }
    }

    li.bookings-menu.active,
    li.notification-menu.active {
      a {
        position: relative;

        &::after {
          content: url("../../images/common/next-icon.svg");
          position: absolute;
          right: 5%;
          top: 34%;
          transform: rotate(180deg);
        }
      }
    }

    li.bookings-menu.show-submenu,
    li.notification-menu.show-submenu {
      a {
        position: relative;

        &::after {
          content: url("../../images/common/next-icon.svg");
          position: absolute;
          right: 5%;
          top: 21%;
          transform: rotate(0deg);
        }
      }
    }

    li {
      cursor: pointer;

      .submenus {
        padding: 10px;
        padding-left: 10px;
        padding-top: 0px;
        background: #f8f7f6;

        ul {
          padding-left: 10px;
          position: relative;

          &::before {
            content: url("../../images/common/border-line-icon.svg");
            position: absolute;
            left: 0%;
            height: 60px;
            overflow: hidden;
          }

          li {
            padding: 10px 0px;
            padding-left: 8px;
          }

          li.active {
            color: var(--sandy-brown);

            &::before {
              content: url("../../images/common/active-submenu-icon.svg");
              position: absolute;
              left: 0%;
              top: 11%;
            }
          }

          li.inactive {
            padding-bottom: 0px;

            &::before {
              content: url("../../images/common/submenu-icon.svg");
              position: absolute;
              left: 0%;
              bottom: 3%;
            }
          }
        }
      }

      &:hover {
        background-color: var(--light-gray);

        a {
          color: var(--black);
          text-decoration: none;
        }
      }

      a {
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 12px 10px;

        svg {
          margin-right: 10px;
          margin-top: -2px;
        }
      }
    }

    li.bookings-menu {
      .submenus {
        ul {
          &::before {
            height: 122px;
          }

          li.inactive {
            padding-bottom: 10px !important;
          }

          li {
            &:nth-child(1) {
              &::before {
                top: 5%;
              }
            }

            &:nth-child(2) {
              &::before {
                top: 41%;
              }
            }

            &:last-child {
              &::before {
                bottom: 20%;
              }
            }
          }

          li.groups {
            &:last-child {
              &::before {
                bottom: 10%;
              }
            }
          }

          li.active {
            &:nth-child(2) {
              &::before {
                top: 41%;
              }
            }
          }

          li.active {
            &:nth-child(3) {
              &::before {
                top: 69%;
              }
            }
          }
        }
      }

      .mng-icon {
        path {
          stroke: var(--sandy-brown);
        }
      }
    }

    li.booking-main.bookings-menu .submenus ul {
      &::before {
        height: 90px;
      }

      li {
        &:nth-child(2)::before {
          top: 65%;
        }
      }
    }

    li.user-management .submenus ul::before {
      height: 97px;
    }

    li.bookings-menu.mobicom-web {
      .submenus {
        ul {
          &::before {
            height: 19px;
          }

          li {
            &:nth-child(1)::before {
              top: 18%;
            }
          }
        }
      }
    }

    li.notification-menu,
    li.member-stats-menu {
      .submenus {
        ul {
          &::before {
            height: 58px;
          }

          li.inactive {
            padding-bottom: 10px !important;
          }

          li {
            &:nth-child(1) {
              &::before {
                top: 8%;
              }
            }

            &:nth-child(2) {
              &::before {
                top: 59%;
              }
            }
          }

          li.active {
            &:nth-child(2) {
              &::before {
                top: 59%;
              }
            }
          }
        }
      }
    }
  }

  .bookings-menu.mobicom-web {
    .sidebar .main li.bookings-menu.mobicom-web .submenus ul::before {
      height: 17px;
    }
  }
}

.sidebar-main {
  a {
    &:hover {
      color: var(--dark-gray);
      text-decoration: none;
    }
  }
}