.club-profile-page {
  .clubs-profile {
    .page-details {
      .bottom-section {
        padding: 14px 20px;
        .profile-details {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 20px;
          .left {
            .img-wrapper {
              height: 200px;
              border-radius: 9px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .name {
              margin-top: 5px;
              font-size: 15px;
              font-weight: 400;
            }
            .location {
              font-size: 13px;
            }
          }
          .right {
            table {
              tbody {
                tr {
                  border-bottom: 1px solid var(--soft-gray-type-1);
                }
              }
            }
            .info-box {
              .item {
                margin-bottom: 5px;
                .title {
                  font-weight: 500;
                }
                p {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .swiper-section {
          position: relative;
          .swipet-button {
            .prev-icon {
              top: 40%;
              left: 5%;
            }
            .next-icon {
              top: 40%;
              right: 5%;
            }
            .prev-icon,
            .next-icon {
              position: absolute;
              z-index: 10;
              background: var(--white);
              border: 1px solid transparent;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              padding: 0px;
              img {
                width: 21px;
              }
            }
          }
          .swiper-slide {
            .slide-card {
              .img-wrapper {
                height: 235px;
                @media screen and (max-width: 1400px) {
                  height: 232px;
                }
                @media (min-width: 1000px) and (max-width: 1300px) {
                  height: 258px;
                }
              
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
      .tabs-panel {
        padding: 0px 20px;
        padding-bottom: 20px;
        .profile-tabs {
          min-height: 200px;
          ul {
            .nav-item {
              button {
                color: var(--dark-gray);
                font-size: 15px;
              }
              button.nav-link.active {
                font-weight: 500;
              }
            }
          }
          .tab-content{
            .club-facility-list{
              .facility-card{
                text-align: center;
                padding: 18px 10px;
                border-radius: 10px;
                border: 1px solid var(--border-color);
                .img-wrapper{
                  img{
                    width: 100px;
                    object-fit: contain;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
        .affiliated-clubs {
          ul {
            padding-left: 30px;
            list-style: disc;
            li {
              margin-bottom: 4px;
            }
          }
        }

        .board-members {
          li {
            text-align: center;
            max-width: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 7px;
            border: 1px solid var(--bs-border-color);
            border-radius: 9px;
            .img-wrapper {
              height: 80px;
              max-width: 80px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .name {
              font-size: 16px;
              margin-top: 4px;
              font-weight: 500;
              min-height: 45px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .designation {
              font-size: 13px;
            }
          }
        }
        .board-members.facilities {
          .img-wrapper {
            img {
              width: auto;
            }
          }
        }
        .facility-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 5px;
          height: 100%;
          box-sizing: border-box; 
        }
        
        .facility-image {
          width: 100%;
          height: auto;
          border-radius: 5px;
          margin-bottom: 10px;
        }
        
        .facility-name {
        
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: auto;
        }
        
        .row {
          display: flex;
          flex-wrap: wrap;
         
        }
        
        
        .facility-image {
         
          height: auto;
          border-radius: 5px;
          margin-bottom: 10px;
          object-fit: contain;
          width: 100px;
          margin: auto;
          margin-bottom: 10px
        }
        
        .facility-name {
      
          font-size: 14px;
        }
        
      }
    }
  }
}

.create-clubs-profile-page {
  .club-profile-section {
    .board-members-section {
      .img-wrapper.cropped-image{
        width: 50px;
        height: 50px;
        border: 1px solid var(--border-color);
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .table {
        thead tr th {
          font-weight: 500;
          font-size: 15px;
        }
      }
    }
    .added-members-table{
      .profile-image{
        width: 40px;
        height: 40px;
        border-radius: 50%;        
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.member-images-upload-modal {
  .modal-dialog {
    max-width: 750px;
    .modal-body {
      padding: 27px 17px;
      .swiper-section {
        position: relative;
        .swipet-button {
          .prev-icon {
            transform: rotate(180deg);
            top: 40%;
            left: 0%;
          }
          .next-icon {
            top: 40%;
            right: 0%;
          }
          .prev-icon,
          .next-icon {
            position: absolute;
            z-index: 10;
            background: var(--white);
            border: 1px solid rgb(204, 204, 204);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            padding: 0px;
            img {
              width: 21px;
            }
          }
        }
        .swiper-slide {
          .slide-card {
            .img-wrapper {
              height: 250px;
              border-radius: 9px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
      .cropping-image-input {
        background-color: var(--sandy-brown);
        padding: 8px 18px;
        border-radius: 32px;
        width: 239px;
      }
    }
    .modal-header {
      padding-top: 10px;
      padding-bottom: 10px;
      .modal-title {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    input {
      &::placeholder {
        font-size: 14px;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .member-image-list {
      .image-item {
        .details {
          padding: 15px;
          .profile-image {
            border-radius: 5px;
            padding: 25px 14px;
            padding-top: 17px;
            cursor: pointer;
            .img-wrapper {
              height: 76px;
              width: 76px;
              border-radius: 50%;
              overflow: hidden;
              margin: auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              p {
                text-align: center;
                margin-top: 5px;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .item.upload {
      .upload-icon {
        border: 1px solid var(--light-grayish);
        border-radius: 10px;
        padding: 22px 35px;
        div {
          color: var(--medium-gray);
          font-size: 15px;
          text-align: center;
        }
      }
      .info {
        font-size: 12px;
        margin-left: 10px;
        color: var(--dark-shade-gray);
      }
      .uploaded-images {
        width: 130px;
        height: 130px;
        padding: 10px;
        border: 1px solid var(--light-grayish);
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .uploaded-images.active {
        .pos-rel {
          position: relative;
          .close-btn {
            display: flex;
            justify-content: center;
            align-items: start;
            position: absolute;
            top: -24%;
            right: -23%;
            background: rgb(112, 112, 112);
            border: none;
            color: rgb(255, 255, 255);
            border-radius: 50%;
            width: 18px;
            height: 18px;
            cursor: pointer;
            font-size: 13px;
            line-height: 14.5px;
            text-align: center;
          }
        }
      }
      .filename {
        margin-top: 2px;
        font-size: 12px;
        max-width: 130px;
        svg {
          cursor: pointer;
          min-width: 16px;
          min-height: 16px;
        }
      }
    }
  }
}

.edit-board-member-modal {
  .modal-dialog {
    .modal-header {
      .modal-title {
        font-weight: 500;
        font-size: 24px;
      }
    }
    .img-wrapper {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.image-cropping-modal {
  .modal-dialog {
    max-width: 750px;
    .reactEasyCrop_Container {
      position: relative;
      width: 100%;
      height: 350px;
    }
    .image-cropping-sizes {
      button {
        padding: 5px 10px;
        background: var(--white);
        border: 1px solid var(--border-color);
        border-radius: 6px;
        &:hover{
          background-color: var(--sandy-brown);
        }
      }
      button.active{
        background-color: var(--sandy-brown);
      }
    }
  }
}

// My Club - Club Profile
.clubs-profile.myclub-profile-page{
  .page-details{
    .top-section{
      background-color: var(--soft-gray) !important;
      .right .add-clubs-info{
        border: 1px solid var(--sandy-brown);
        background-color: var(--white) !important;
        svg{
          fill: black;
        }
      }
    }
    .bottom-section{
        .swiper-section {
          .club-name{
            position: absolute;
            z-index: 100;
            bottom:0%;
            left: 2.87%;
            width: 94.34%;
            padding: 15px;
            color: var(--white);
            font-size: 17px;
            border: 1px solid var(--sandy-brown);
            background: #00000057;
            @media screen and (max-width: 1400px) {
              width: 93.34%;
            }
          }
          .swipet-button{
            button{
              width: 30px !important;
              height: 30px !important;
            }
            .next-icon{
              bottom: 6%;
              right: 5%;
              z-index: 110 !important;
              top: unset;
              left: unset;
            }
            .prev-icon{
              bottom: 6%;
              right: 18%;
              z-index: 110 !important;
              top: unset;
              left: unset;
            }
          }
        }
        .profile-basic-details{
          .underline-link{
            text-decoration: underline !important;
          }
          .info-card {
            background: #fff;
            border-radius: 8px;
            padding: 15px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;
            .row{
              row-gap: 16px;
              .br-0{
                border-right: none;
              }
            }
            .item{
              display: flex;
              border-right: 1px solid var(--sandy-brown);
              // justify-content: space-around;
              align-items: center;
            }
        }
        .info-label {
            display: block;
            color: #333;
            margin-right: 8px;
            font-weight: 500;
            font-size: 15px;
            @media screen and (max-width: 1400px) {
              font-size: 14px;
            }
        }
        .info-value {
            color: #555;
            display: block;
            word-break: break-word;
            font-size: 15px;
        }
        .info-value.primary-address{
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .tabs-panel .profile-tabs{
      background-color: var(--almond-white);
      .tab-content{
        padding: 20px;
        .search-input{
          background: var(--alabaster);
          width: 250px;
        }
        .aboutus{
          .img-wrapper{
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .honors{}
        .affiliated-clubs{
          .club-item{
            padding: 10px 23px;
            padding-left: 10px;
            border: 1px solid var(--beige);
            border-radius: 12px;
            .name{
              font-weight: 500;
            }
          }
        }
        .board-members{
          .profile-item{
            display: flex;
            align-items: center;
            column-gap: 20px;
            background: #fff;
            border-radius: 10px;
            padding: 15px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
            transition: box-shadow 0.3s ease-in-out;
            .img-wrapper{
              width: 50px;
              height: 50px;
              border-radius: 50%;

              img{
                width: 50px;
                height: 50px;
                object-fit: cover;
                border: 1px solid var(--sandy-brown);
                border-radius: 50%;
              }
            }
          }
        }
        .facilities{}
      }
      ul{
        column-gap: 10px;
        background: var(--white);
        padding-bottom: 15px;
        row-gap: 20px;
        .nav-item{
          width: 175px;
          flex: none;
          button.nav-link{
            border: 1px solid var(--sandy-brown);
            border-radius: 7px;
            margin-bottom: 3px;
          }
          button.nav-link.active{
            background: var(--sandy-brown);            
          }
        }
      }
    }
  }
}
