.buttonletter-container {
  display: flex;
  gap: 10px; 
  

  .custom-button {

    background-color: var(--sandy-brown);
    color: white !important;
    border: none;

    font-size: 14px;
    width: 239px;
    height: auto;
    text-align: center;
    transition: background 0.3s ease;

    &:hover {
      background-color: darken(#c58f5a, 10%);
    }
  }
}
