$light-blue: #f0f7ff;
$sandy-brown: #c58f5a;

$success-green: #28a745;
$danger-red: #dc3545;
$white: #fff;



.club-cards-container {

  .club-card {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    background-color: white;
    border: 1px solid var(--soft-gray);
    // border-radius: 12px;
    // padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .card-inner {
      padding: 20px;

      .club-logo {
        width: 120px;
        height: 121px;
        margin-right: 15px;

        img {
          width: 117px;
          height: 121px;
          object-fit: cover;

        }
      }

      .club-info {
        .club-name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          color: var(--charcol-gray);
        }

        .club-location {
          font-size: 14px;
          margin-bottom: 5px;
          color: var(--secondary-color);

          i {
            margin-right: 5px;
          }
        }

        .club-type {
          font-size: 13px;
          color: var(--secondary-color);
          margin-bottom: 0;
        }
      }
    }

    .request-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 7px;
      border-left: 1px solid var(--soft-gray);
      background-color: #dde5f6;

      .request-label {
        font-size: 14px;
        text-align: center;
        color: var(--secondary-color);
        margin-bottom: 10px;
        font-weight: 500;
      }

      .accept-indicator {
        font-size: 14px;

        margin-bottom: 5px;
        font-weight: 500;
      }

      .block-indicator {
        font-size: 14px;

        margin-top: 5px;
        font-weight: 500;
      }

      .toggle-switch-vertical {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .toggle-track {
          width: 25px;
          height: 45px;
          background-color: #e56b6b;
          border-radius: 22px;
          position: relative;

          cursor: pointer;
          transition: background-color 0.3s ease;

          .toggle-button {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: white;
            left: 4px;
            bottom: 5px;

            transition: transform 0.3s ease;
          }

          &.active {
            background-color: var(--green);

            .toggle-button {
              transform: translateY(-18px);
            }
          }
        }
      }
    }

    .reservation-periods {
      padding: 15px;
      background-color: #f8f9fa;
      border-top: 1px solid #e9ecef;

      .reservation-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        span {
          font-size: 14px;
          font-weight: 500;
          color: #495057;
        }
      }

      .reservation-options {
        display: flex;
        justify-content: space-between;

        .reservation-option {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 5px;

          .period-label {
            margin-bottom: 8px;
            font-size: 13px;
            color: #6c757d;
          }

          .toggle-container {
            .toggle-switch {
              width: 45px;
              height: 22px;
              background-color: #e9ecef;
              border-radius: 22px;
              position: relative;
              cursor: pointer;
              transition: background-color 0.3s ease;

              .toggle-button {
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: white;
                left: 2px;
                top: 2px;
                transition: transform 0.3s ease;
              }

              &.active {
                background-color: #28a745;

                .toggle-button {
                  transform: translateX(23px);
                }
              }
            }
          }
        }
      }
    }
  }


  .club-cardpcw {
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  
    .card-inner {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 7px;
  
      .club-logo img {
        width: 81px;
        height: 70px;
        object-fit: cover;
        border-radius: 5px;
      }
  
      .club-info {
        flex-grow: 1;
  
        .club-name {
          font-size: 16px;
          font-weight: 600;
        }
  
        .club-location {
          font-size: 14px;
          color: #666;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  
    .reservation-container {
      padding: 10px;
      background: var(--light-sandy);
      border-top: 1px dashed var(--silver-gray);
      border-radius: 5px;
  
      .reservation-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 10px;
        flex-wrap: wrap;
  
        .toggle-switch {
          width: 44px;
          height: 20px;
          background-color: #ddd;
          border-radius: 20px;
          position: relative;
          cursor: pointer;
          transition: background-color 0.3s;
  
          .toggle-button {
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: white;
            left: 2px;
            top: 1px;
            transition: transform 0.3s;
          }
  
          &.active {
            background-color: #28a745;
  
            .toggle-button {
              transform: translateX(20px);
            }
          }
        }
      }
  
      .reservation-inputs {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
  
        .input-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          width: auto;
  
          input {
            width: 100px;
            height: 24px;
            text-align: center;
            padding: 5px;
            border: 1px solid #ccc;
            background: white;
          }
        }
      }
    }
  }
  
 


}


.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-item .btn {
  padding: 8px 15px;
  border-radius: 5px;
}

.page-item.active .btn {
  background-color: var(--sandy-brown);
  color: white;
}

.page-item.disabled .btn {
  opacity: 0.5;
  pointer-events: none;
}


.request-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-left: 1px solid var(--soft-gray);
  background-color: #dde5f6;

  .request-label {
    font-size: 13px;
    text-align: center;
    color: var(--secondary-color);
    margin-bottom: 10px;
    font-weight: 500;
  }

  .accept-indicator {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .block-indicator {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
  }

  .toggle-switch-vertical {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .toggle-track {
      width: 22px;
      height: 40px;
      background-color: $danger-red;
      border-radius: 22px;
      position: relative;
      cursor: pointer;
      transition: background-color 0.3s ease;

      .toggle-button {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;
        left: 2px;
        bottom: 2px;
        transition: transform 0.3s ease;
      }

      &.active {
        background-color: $success-green;

        .toggle-button {
          transform: translateY(-18px);
        }
      }
    }
  }
}


// New Reservation Card Styles
.reservation-card {
  flex-direction: column;
  height: 100%;
  padding: 0;

  .card-header {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid var(--soft-gray);

    .logo-section {
      width: 60px;
      height: 60px;
      margin-right: 15px;

      .club-logo-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .club-details {
      flex: 1;

      .club-name {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        color: var(--charcol-gray);
      }

      .club-location {
        font-size: 14px;
        margin-bottom: 3px;
        color: var(--secondary-color);

        i {
          margin-right: 5px;
        }
      }

      .club-type {
        font-size: 13px;
        color: var(--secondary-color);
        margin-bottom: 0;
      }
    }
  }

  .reservation-section {
    padding: 20px;

    .restrict-reservations {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 500;
      color: var(--charcol-gray);

      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
        margin-left: 10px;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked+.slider {
            background-color: $success-green;

            &:before {
              transform: translateX(26px);
            }
          }
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: .4s;

          &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 3px;
            bottom: 5px !important;
            background-color: white;
            transition: .4s;
          }

          &.round {
            border-radius: 34px;

            &:before {
              border-radius: 50%;
            }
          }
        }
      }
    }

    .reservation-limits {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .limit-row {
        display: flex;
        align-items: center;

        .limit-label {
          width: 80px;
          font-size: 14px;
          color: var(--charcol-gray);
          font-weight: 500;
        }

        .limit-input {
          flex: 1;

          input {
            height: 36px;
            text-align: center;
            border: 1px solid var(--soft-gray);
            border-radius: 4px;
            font-size: 14px;

            &:focus {
              outline: none;
              border-color: var(--white);
              box-shadow: 0 0 0 0.2rem rgba(var(--white), 0.25);
            }

            &:read-only {
              background-color: #f8f9fa;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}


// Pagination styling
.pagination-container {
  .showing-entries {
    color: var(--secondary-color);
    font-size: 14px;
  }

  .pagination-controls {
    .btn {
      min-width: 100px;
    }
  }
}

// No data message
.no-data-message {
  padding: 30px;
  color: var(--secondary-color);
  font-size: 16px;
}


// .custom-confirm-button-class {
//   background-color: var(--white) !important;
//   &:hover {
//     background-color: darken(var(--white), 10%) !important;
//   }
// }

// .custom-cancel-button-class {
//   background-color: var(--secondary-color) !important;
//   &:hover {
//     background-color: darken(var(--secondary-color), 10%) !important;
//   }
// }


.custom-sweetalert-title {
  font-size: 18px;
  color: var(--charcol-gray);
}

// Loading spinner
.spinner-border {
  width: 3rem;
  height: 3rem;
}

// Responsive styles
@media (max-width: 1251px) and (min-width: 1024px) {
  .club-cards-container {
    .row {

      .col-md-6,
      .col-lg-4 {
        width: 50%;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 758px) {
  .club-cards-container {
    .row {

      .col-md-6,
      .col-lg-4 {
        width: 100%; // One card per row
      }
    }
  }
}

// Search box with clear icon styling
.search-box {
  position: relative;

  input {
    padding-right: 30px;

    &:focus {
      border-color: var(--white);
      box-shadow: 0 0 0 0.2rem rgba(var(--white), 0.25);
    }
  }

  .clear-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--secondary-color);

    &:hover {
      color: var(--charcol-gray);
    }
  }
}