.rct-icons-fa4 .rct-icon-parent-close::before{
    content: "→" !important;
}

.rct-icons-fa4 .rct-icon-parent-open::before {
    content: "↓" !important;
}

.dropdwon_in_table{
    padding: 5px;
    border-radius: 15px;
    color: var(--white);
}

.user-management-section{
    .page-details {
        background-color: var(--white);
        padding: 29px 20px;

        .details{
            padding: 10px;
            .password-input-pasw{
                position: relative;
                input{
                    padding-right: 33px;
                }
                .view-password-icon{
                    width: fit-content;
                    position: absolute;
                    top: 50%;
                    right: 5%;
                    @media screen and (min-width: 1700px) {
                        top: 40%;
                        right: 10%;
                    }
                }
            }
        }
        .rct-node.rct-node-parent{
            .rct-checkbox[aria-checked="false"] svg {
                border: 1px solid var(--bs-border-color);
                path{
                    fill: var(--white);
                }
            }
            .rct-node-icon{
                color: var(--sandy-brown) !important;
            }
        }
    }
}