.hexagonpcn {
    position: relative;
    width: 85px;
    height: 84px;
    margin: 0 auto;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    background-color: #0673dc;
    
    display: flex;
    justify-content: center;
    align-items: center; /* Center content horizontally and vertically */
  
    h5 {
      color: white;
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      padding: 2px;
      text-align: center;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; /* Center text inside the hexagon */
  
      span {
        display: block;
      }
    }
  }