.dashboard-section{
    margin-bottom: 50px;
    .main{
       .widget-tiles-grid{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            .item{
                padding: 20px;
                border: 1px solid #707070;
                border-radius: 13px;
                background: linear-gradient(71deg, #4d2d16 60%, #5e3517 80%);
                color: var(--white);
                min-height: 167px;
                .top{
                    .count{
                        font-size: 22px;
                        color: var(--sandy-brown);
                        font-weight: 500;
                    }
                    .title{
                        font-size: 20px;
                        font-weight: 300
                    }
                    a{
                        color: var(--white);
                        text-decoration: underline;
                        font-weight: 300;
                    }
                }
                .bottom{
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    column-gap: 20px;
                    margin-top: 20px;
                    .c-item{
                        text-align: center;
                        font-size: 14px;
                    }
                }
            }
            @media screen and (max-width: 1360px) {
                .item .top .title{
                    font-size: 18px;
                }
            }
            @media screen and (max-width: 1360px) {
                grid-template-columns: repeat(2, 1fr);            
            }
       }       
    }
}