.platinum-club-netadd {
  .filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label {
      margin-right: 10px;
    }

    .additional-filter {
      margin-left: 20px;
      padding: 5px;
    }
  }

  .club-list {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 10px;
    padding: 10px;

    .club-number {
      font-weight: bold;
      font-size: 18px;
      color: #333;
      position: absolute;
      top: 10px;
      left: 10px;
      background: rgba(255, 255, 255, 0.7);
      padding: 5px;
      border-radius: 50%;
      z-index: 10; /* Ensure it's above other content */
    }

    .club-card {
      background-color: white;
      margin: 5px 0;
      padding: 10px;
      border: 1px solid #ddd;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      position: relative; 
      height: 150px;
      
      h3, p {
        font-size: 12px;
        line-height: 15px;
        min-height: 30px;
        text-align: center;
        width: 140px;
    
      }

      .club-image {
        width: 81px;
        height: 64px;
        margin-bottom: 5px;
        object-fit: cover;
      }
    }
  }
}
