.create-curated-sponsorship-page{
    .page-details{
        background-color: var(--white);
        padding: 29px 20px;
        input, textarea{
            &:focus{
                box-shadow: none;
                border: 1px solid var(--light-grayish);
            }
        }    
        label{
            color: var(--dark-gray);
        }   
    }
}