.create-solitaire-network.create-clubs-info-section{
    input{
        &::placeholder{
            font-size: 14px;
        }
        &:focus{
            box-shadow: none;
        }
    }
    .clubs-list{        
        .grid-container{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            li{
                position: relative;
                svg{
                    position: absolute;
                    right: -3%;
                    top: -5%;
                    background: #e6e6e6;
                    border-radius: 50%;
                    width: 19px;
                    height: 19px;
                }
                .img-wrapper{
                    height: 130px;
                    border-radius: 9px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);                
            }
        }
    }
}