.login-page{
    .login-page {
        background-image: url('../../images/login/loginbackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right center;
        width: 100%;
        min-height: 100vh;
        .form-control.is-invalid, .was-validated .form-control:invalid {
            background-position: right calc(1.975em + .1875rem) center !important;
        }
        .formd-section {
            width: 100%;


            .formd-main {
                margin-left: 110px;

                // margin-top: 100px;
                padding-top: calc(55px + 30px);

                @media only screen and (max-width:992px) and (min-width:570px) {
                    margin-left: 200px;

                }

                @media only screen and (max-width:470px) {
                    margin-left: 30px;

                }

                @media only screen and (max-width:390px) {
                    margin-left: 15px;

                }

                @media only screen and (max-width:325px) {
                    margin-left: 5px;

                }

                @media only screen and (min-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 68px);
                }

                @media only screen and (max-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 20px);
                }

                @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 25px);
                }

                @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 25px);
                }

                .heading {
                    margin-left: 50px;
                    width: 400px;
                    padding-top: 20px;

                    @media only screen and (min-width:1700px) {
                        padding-top: 80px;

                    }

                    @media only screen and (max-width:1130px) {
                        width: 350px;

                    }

                    @media only screen and (max-width:1019px) and (min-width:992px) {
                        width: 300px;

                    }

                    @media only screen and (max-width:768px) {
                        width: 250px;

                    }

                    @media only screen and (min-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        padding-top: 10px;
                    }

                    @media only screen and (max-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        padding-top: 10px;
                    }

                    @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        padding-top: 10px;
                    }

                    @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        padding-top: 10px;
                    }

                    .haed {
                        font-size: 45px;
                        color: var(--white);
                        text-align: center;
                        font-weight: 500;
                    }

                    .sub-head {
                        font-size: 18px;
                        color: var(--white);
                        text-align: center;
                    }
                }

                .form-content {
                    padding-top: 15px;

                    @media only screen and (min-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        padding-top: 0px;
                    }

                    @media only screen and (max-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        padding-top: 0px;
                    }

                    @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        padding-top: 0px;
                    }

                    @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        padding-top: 0px;
                    }

                    .email-main {

                        position: relative;

                        .form-label {
                            color: var(--beige);
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .form-control {
                            border: 1px solid var(--white);
                            background-color: var(--charcoalblack);
                            color: var(--white);
                            height: 45px;
                            padding-right: 30px;


                            &:focus {
                                box-shadow: none;
                                // border-color: transparent;
                            }

                            &::placeholder {
                                color: var(--white);
                                font-size: 14px;
                            }

                            &:-webkit-autofill {
                                // background-color: yellow !important;
                                -webkit-text-fill-color: var(--white) !important;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }

                        .image-wrapper {
                            position: absolute;
                            top: 40px;
                            right: 3%;
                            width: 15px;
                            height: 15px;                           

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                
                            }
                        }
                    }

                    .pswd-main {
                        margin-top: 20px;
                        position: relative;
                        svg{
                            stroke: var(--light-taupe);
                            fill: var(--light-taupe);
                        }

                        @media only screen and (min-width:443px) and (max-height:480px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        @media only screen and (max-width:443px) and (max-height:480px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        .form-label {
                            color: var(--beige);
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .form-control {
                            border: 1px solid var(--white);
                            background-color: var(--charcoalblack);
                            color: var(--white);
                            height: 45px;
                            padding-right: 30px;

                            &:focus {
                                box-shadow: none;
                                // border-color: transparent;
                            }

                            &::placeholder {
                                color: var(--white);
                            }

                            &:-webkit-autofill {
                                // background-color: yellow !important;
                                -webkit-text-fill-color: var(--white) !important;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }

                        .img-wrapper {
                            position: absolute;
                            top: 42px;
                            right: 3%;
                            width: 15px;
                            height: 15px;
                            @media screen and (max-width: 1400px) {
                                top: 42px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }

                    .login-forgot {
                        padding-top: 15px;
                        text-align: end;

                        @media only screen and (min-width:443px) and (max-height:480px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        @media only screen and (max-width:443px) and (max-height:480px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                            padding-bottom: 0;
                            padding-top: 0px;
                        }

                        .forgot {
                            font-size: 14px;
                            color: var(--white);
                            text-decoration: none;
                            user-select: none;
                        }
                    }

                    .sign {
                        margin-top: 10px;

                        .sign-button {
                            color: var(--white);
                            background-color: var(--sandy-brown);
                            border: 1px solid var(--sandy-brown);
                            height: 48px;

                            &:hover {
                                opacity: 0.95;
                            }

                        }

                    }

                    .Active {
                        text-align: center;
                        margin-top: 10px;


                        p {
                            color: var(--white);
                            font-size: 14px;

                        }

                        span {
                            color: var(--sandy-brown);
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }
                }

                .privacypolicy {
                    padding-top: 40px;

                    @media only screen and (min-width:1700px) {
                        padding-top: 40px;

                    }

                    @media only screen and (min-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        padding-top: 5px;
                    }

                    @media only screen and (max-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        padding-top: 5px;
                    }

                    @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        padding-top: 5px;
                    }

                    @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        padding-top: 5px;
                    }

                    p {
                        font-size: 13px;
                        color: var(--grayishblue);
                    }

                    span {
                        color: var(--sandy-brown);
                        font-size: 13px;
                    }
                }

            }
            .privacypolicy {
                p {
                    font-size: 13px;
                    color: var(--grayishblue);
                }

                span {
                    color: var(--sandy-brown);
                    font-size: 13px;
                }
            }
        }
    }
}

.header-container.login-heaed{
    background-color: var(--black);
    color: var(--light-gray);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 20;
    padding: 10px 0px;
    @media screen and (max-width: 576px) {
        .login-img-wrapper{
            width: 64%;
            img{
                height: auto !important;
                width: 197px;
            }            
        }
        .member-btn {
            gap: unset !important;
        }
    }
    .club-login-button{
        align-items: center;
        background-color: var(--sandy-brown);
        color: var(--white);
        display: flex;
        height: 30px;
        justify-content: center;
        width: 120px;
        border: 1px solid var(--sandy-brown);
        border-radius: 5px;
        font-family: 'HelveticaNeue';
        @media screen and (max-width: 350px) {
            font-size: 12px;
            width: 100px;
            margin-left: 4px;
        }
    }
}
// forgot page
.forgot {
    .forgot-page {
        background-image: url('../../images/login/loginbackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 100vh;
        .form-control.is-invalid, .was-validated .form-control:invalid {
            background-position: right calc(1.975em + .1875rem) center !important;
        }
        .formd-section {
            width: 100%;


            .formd-main {
                margin-left: 110px;

                // margin-top: 100px;
                padding-top: calc(55px + 100px);
                padding-bottom: 100px;

                @media only screen and (min-width:1600px) {
                    padding-top: calc(55px + 245px);
                    padding-bottom: 300px;

                }

                @media only screen and (max-width:992px) and (min-width:570px) {
                    margin-left: 200px;

                }

                @media only screen and (max-width:599px) {
                    margin-left: 50px;

                }

                @media only screen and (max-width:470px) {
                    margin-left: 40px;

                }

                @media only screen and (max-width:390px) {
                    margin-left: 15px;

                }

                @media only screen and (max-width:325px) {
                    margin-left: 5px;

                }

                @media only screen and (min-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 68px);
                }

                @media only screen and (max-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    padding-top: 0;
                }

                @media only screen and (min-width:320px) and (min-height:480px) and (max-height:600px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 39px);
                }

                .heading {
                    margin-left: 50px;
                    width: 400px;
                    padding-bottom: 20px;
                    @media only screen and (max-width:1130px) {
                        width: 390px;
                        margin-left: 0;

                    }

                    // @media only screen and (max-width:1019px)  and (min-width:992px) {
                    //     width: 30px;

                    // }
                    @media only screen and (max-width:992px) {
                        width: 390px;
                        margin-left: 10px;

                    }

                    @media only screen and (max-width:444px) {
                        width: 250px;

                    }

                    .haed {
                        font-size: 38px;
                        color: var(--white);
                        text-align: center;    
                        font-weight: 500;                    
                    }

                    .sub-head {
                        font-size: 18px;
                        color: var(--white);
                        text-align: center;
                    }
                }

                .form-content {

                    .email-main {
                        padding-top: 10px;
                        position: relative;

                        .form-label {
                            color: var(--beige);
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .form-control {
                            border: 1px solid var(--white);
                            background-color: var(--charcoalblack);
                            color: var(--white);
                            height: 45px;
                            padding-right: 30px;                            

                            &:focus {
                                box-shadow: none;
                                // border-color: transparent;
                            }

                            &::placeholder {
                                color: var(--white);
                                font-size: 14px;
                            }

                            &:-webkit-autofill {
                                // background-color: yellow !important;
                                -webkit-text-fill-color: var(--white) !important;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }
                        
                        .image-wrapper {
                            position: absolute;
                            top: 50px;
                            right: 3%;
                            width: 15px;
                            height: 15px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }                        
                    }

                    .pswd-main {
                        position: relative;

                        .form-label {
                            color: var(--beige);
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .form-control {
                            width: 400px;
                            border: 1px solid var(--white);
                            background-color: var(--charcoalblack);
                            color: var(--white);
                            height: 45px;

                            @media only screen and (max-width:1024px) {
                                width: 350px;

                            }

                            @media only screen and (max-width:365px) {
                                width: 310px;

                            }

                            &:focus {
                                box-shadow: none;
                                // border-color: transparent;
                            }

                            &::placeholder {
                                color: var(--white);
                            }

                            &:-webkit-autofill {
                                // background-color: yellow !important;
                                -webkit-text-fill-color: var(--white) !important;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }

                        .img-wrapper {
                            position: absolute;
                            top: 60px;
                            right: 100px;

                            @media only screen and (min-width :1600px) {
                                right: 300px;

                            }

                            @media only screen and (max-width :1400px) {
                                right: 90px;
                            }

                            @media only screen and (max-width :1300px) {
                                right: 70px;
                            }

                            @media only screen and (max-width :1200px) {
                                right: 69px;
                            }

                            @media only screen and (max-width :1024px) and (min-width :992px) {
                                right: 40px;

                            }

                            @media only screen and (max-width :992px) and (min-width :900px) {
                                right: 320px;
                            }

                            @media only screen and (max-width :992px) and (min-width :900px) {
                                right: 320px;
                            }

                            @media only screen and (max-width :900px) and (min-width :800px) {
                                right: 280px;
                            }

                            @media only screen and (max-width :768px) {
                                right: 200px;
                            }

                            @media only screen and (max-width :600px) {
                                right: 80px;
                            }

                            @media only screen and (max-width : 440px) {
                                right: 30px;
                            }

                            @media only screen and (max-width : 390px) {
                                right: 0px;
                            }

                            @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                                top: 40px;
                            }

                            @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                                top: 40px;
                            }
                        }
                    }

                    .login-forgot {
                        width: 400px;
                        text-align: end;

                        @media only screen and (max-width:1024px) {
                            width: 350px;

                        }

                        @media only screen and (max-width:365px) {
                            width: 310px;

                        }

                        .forgot {
                            font-size: 14px;
                            color: var(--white);
                            text-decoration: none;
                        }
                    }

                    .sign {
                        padding-top: 20px;

                        .sign-button {
                            color: var(--white);
                            background-color: var(--sandy-brown);
                            border: 1px solid var(--sandy-brown);
                        }

                    }

                    .Active {
                        text-align: center;
                        padding-top: 20px;

                        p {
                            color: var(--white);
                            font-size: 14px;

                        }

                        span {
                            color: var(--sandy-brown);
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .backto {
                            background-color: transparent;
                            color: var(--beige);
                            font-size: 14px;
                            border: none;

                            span {
                                padding-right: 6px;

                                .left {
                                    color: var(--beige);
                                }
                            }
                        }
                    }

                }



            }

        }

        .magh {
            // margin-bottom: 36px;
            padding-bottom: 38px;
        }
    }

}

// Verify email
.verfiy {
    .verfiy-page {
        background-image: url('../../images/login/loginbackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100vh;

        .formd-section {
            width: 100%;
            .formd-main {
                margin-left: 110px;

                padding-top: calc(55px + 95px);
                padding-bottom: 100px;

                @media only screen and (max-width:992px) and (min-width:570px) {
                    margin-left: 200px;

                }

                @media only screen and (max-width:640px) {
                    margin-left: 50px;

                }

                @media only screen and (max-width:470px) {
                    margin-left: 40px;

                }

                @media only screen and (max-width:390px) {
                    margin-left: 15px;

                }

                @media only screen and (max-width:325px) {
                    margin-left: 5px;

                }

                @media only screen and (min-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 68px);
                }

                @media only screen and (max-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 20px);
                }

                @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 45px);
                }

                @media only screen and (max-width:321px) and (min-height:480px) and (max-height:700px) {
                    padding-bottom: 0;
                    padding-top: calc(55px + 45px);
                }

                .heading {
                    margin-left: 50px;
                    width: 400px;
                    padding-bottom: 20px;

                    @media only screen and (max-width:1130px) {
                        width: 390px;
                        margin-left: 0;

                    }

                    @media only screen and (max-width:992px) {
                        width: 415px;
                        margin-left: 10px;

                    }

                    @media only screen and (max-width:480px) {
                        width: 100%;

                    }

                    @media only screen and (min-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        // padding-top: calc(55px + 68px);
                    }

                    @media only screen and (max-width:443px) and (max-height:480px) {
                        padding-bottom: 0;
                        //    padding-top: calc(55px + 20px);
                    }

                    @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                        padding-bottom: 0;
                        // padding-top: calc(55px + 39px);
                    }


                    .haed {
                        font-size: 38px;
                        color: var(--white);
                        text-align: center;
                        font-weight: 500;
                    }

                    .sub-head {
                        font-size: 14px;
                        color: var(--white);
                        text-align: center;
                    }
                }                

                .sub {
                    margin-left: 0px;
                }

                .form-content {



                    .otp-main {
                        padding-bottom: 25px;
                        padding-top: 25px;

                        @media only screen and (min-width:443px) and (max-height:480px) {
                            padding-bottom: 9px;
                            padding-top: 10px;
                        }

                        @media only screen and (max-width:443px) and (max-height:480px) {
                            padding-bottom: 9px;
                            padding-top: 10px;
                        }

                        @media only screen and (min-width:321px) and (min-height:480px) and (max-height:700px) {
                            padding-bottom: 0px;
                            padding-top: 0px;
                        }

                        .otp-mao {
                            font-size: 16px;
                            color: var(--sandy-brown);
                            font-family: "Graphik Arabic";
                            font-weight: 300;
                        }

                        .otp-input {

                            input {

                                width: 40px !important;
                                height: 40px !important;
                                color: var(--white) !important;
                                background-color: var(--charcoalblack) !important;
                                border: 1px solid var(--white) !important;
                                font-size: 14px !important;
                                border-radius: 5px !important;


                                &:focus-visible {
                                    outline: none;

                                }
                            }
                        }
                    }

                    .login-forgot {
                        width: 400px;
                        text-align: end;

                        @media only screen and (max-width:1024px) {
                            width: 350px;

                        }

                        @media only screen and (max-width:365px) {
                            width: 310px;

                        }

                        .forgot {
                            font-size: 14px;
                            color: var(--white);
                            text-decoration: none;
                        }
                    }


                    .sign {
                        padding-top: 20px;

                        .sign-button {
                            width: 540px;
                            color: var(--white);
                            background-color: var(--sandy-brown);
                            border: 1px solid var(--sandy-brown);

                            @media only screen and (max-width:1300px) {
                                width: 450px;

                            }

                            @media only screen and (max-width:1130px) {
                                width: 400px;

                            }

                            @media only screen and (max-width:1024px) {
                                width: 350px;

                            }

                            @media only screen and (max-width:365px) {
                                width: 310px;

                            }
                        }

                    }


                    .Active {
                        width: 540px;
                        text-align: center;
                        padding-top: 20px;

                        @media only screen and (max-width:1300px) {
                            width: 450px;

                        }

                        @media only screen and (max-width:1130px) {
                            width: 400px;

                        }

                        @media only screen and (max-width:1024px) {
                            width: 350px;

                        }

                        @media only screen and (max-width:365px) {
                            width: 310px;

                        }

                        p {
                            color: var(--white);
                            font-size: 14px;

                        }

                        span {
                            color: var(--sandy-brown);
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .backto {
                            background-color: transparent;
                            color: var(--beige);
                            font-size: 14px;
                            border: none;

                            span {
                                padding-right: 6px;

                                .left {
                                    color: var(--beige);
                                }
                            }
                        }

                        .b1 {
                            text-decoration: underline;
                            color: var(--white);
                            font-family: "Graphik Arabic";
                            font-size: 13px;
                            margin-top: 15px;
                        }
                    }
                }



            }
        }
        .magh {
            // margin-bottom: 36px;
            padding-bottom: 43px;

            @media only screen and (min-width:443px) and (max-height:480px) {
                padding-bottom: 0;
                // padding-top: calc(55px + 68px);
            }

            @media only screen and (max-width:443px) and (max-height:480px) {
                padding-bottom: 0;
                //    padding-top: calc(55px + 20px);
            }

            @media only screen and (min-width:320px) and (min-height:480px) and (max-height:700px) {
                padding-bottom: 0;
                // padding-top: calc(55px + 39px);
            }
        }
    }
}

// reset-password
.newpassword {
    .reset-pswd-page {
        background-image: url('../../images/login/loginbackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 100vh;
        .form-control.is-invalid, .was-validated .form-control:invalid {
            background-position: right calc(1.975em + .1875rem) center !important;
        }
        .formd-section {
            width: 100%;


            .formd-main {
                margin-left: 110px;

                padding-top: calc(55px + 50px);

                @media only screen and (min-width:1600px) {
                    padding-top: calc(55px + 130px);
                }

                @media only screen and (max-width:992px) and (min-width:570px) {
                    margin-left: 200px;

                }

                @media only screen and (max-width:640px) {
                    margin-left: 50px;

                }

                @media only screen and (max-width:470px) {
                    margin-left: 40px;

                }

                @media only screen and (max-width:390px) {
                    margin-left: 15px;

                }

                @media only screen and (max-width:325px) {
                    margin-left: 5px;

                }

                @media only screen and (min-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    // padding-top: calc(55px + 68px);
                }

                @media only screen and (max-width:443px) and (max-height:480px) {
                    padding-bottom: 0;
                    //    padding-top: calc(55px + 20px);
                }

                @media only screen and (min-width:320px) and (min-height:480px) and (max-height:700px) {
                    padding-bottom: 0;
                    // padding-top: calc(55px + 39px);
                }


                .heading {
                    margin-left: 50px;
                    width: 400px;
                    padding-bottom: 20px;                    
                    @media only screen and (min-width:1600px) {
                        width: 450px;
                    }

                    @media only screen and (max-width:1130px) {
                        width: 390px;
                        margin-left: 0;

                    }

                    @media only screen and (max-width:992px) {
                        width: 415px;
                        margin-left: 10px;

                    }

                    @media only screen and (max-width:480px) {
                        width: 250px;

                    }


                    .haed {
                        font-size: 33px;
                        color: var(--white);
                        text-align: center;
                        font-weight: 500;
                    }

                    .sub-head {
                        font-size: 18px;
                        color: var(--white);
                        text-align: center;
                    }
                }

                // .heak {
                //     width: 300px;


                // }

                // .sub {
                //     margin-left: 50px;
                // }

                .form-content {

                    .email-main {
                        padding-top: 10px;

                        .form-label {
                            color: var(--beige);
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .form-control {
                            border: 1px solid var(--white);
                            background-color: var(--charcoalblack);
                            color: var(--white);
                            height: 45px;
                            padding-left: 30px;

                            &:focus {
                                box-shadow: none;
                                // border-color: transparent;
                            }

                            &::placeholder {
                                color: var(--white);
                                font-size: 14px;
                            }

                            &:-webkit-autofill {
                                // background-color: yellow !important;
                                -webkit-text-fill-color: var(--white) !important;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }
                    }

                    .pswd-main {
                        padding-top: 20px;
                        position: relative;

                        .form-label {
                            color: var(--beige);
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .form-control {
                            border: 1px solid var(--white);
                            background-color: var(--charcoalblack);
                            color: var(--white);
                            height: 45px;
                            padding-right: 30px;


                            &:focus {
                                box-shadow: none;
                            }

                            &::placeholder {
                                color: var(--white);
                            }

                            &:-webkit-autofill {
                                -webkit-text-fill-color: var(--white) !important;
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }

                        .img-wrapper {
                            position: absolute;
                            top: 60px;
                            right: 3%;
                            width: 15px;
                            height: 15px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }


                    .login-forgot {
                        width: 400px;
                        text-align: end;

                        @media only screen and (max-width:1024px) {
                            width: 350px;

                        }

                        @media only screen and (max-width:365px) {
                            width: 310px;

                        }

                        .forgot {
                            font-size: 14px;
                            color: var(--white);
                            text-decoration: none;
                        }
                    }

                    .sign {
                        padding-top: 20px;

                        .sign-button {
                            color: var(--white);
                            background-color: var(--sandy-brown);
                            border: 1px solid var(--sandy-brown);
                        }

                    }

                    .Active {
                        text-align: center;
                        padding-top: 20px;

                        p {
                            color: var(--white);
                            font-size: 14px;

                        }

                        span {
                            color: var(--sandy-brown);
                            font-size: 14px;
                            cursor: pointer;
                        }

                        .backto {
                            background-color: transparent;
                            color: var(--beige);
                            font-size: 14px;
                            border: none;

                            span {
                                padding-right: 6px;

                                .left {
                                    color: var(--beige);
                                }
                            }
                        }
                    }



                }



            }

        }

        .magh {
            // margin-bottom: 36px;
            padding-bottom: 43px;

            @media only screen and (min-width:443px) and (max-height:480px) {
                padding-bottom: 0;
                // padding-top: calc(55px + 68px);
            }

            @media only screen and (max-width:443px) and (max-height:480px) {
                padding-bottom: 0;
                //    padding-top: calc(55px + 20px);
            }

            @media only screen and (min-width:320px) and (min-height:480px) and (max-height:700px) {
                padding-bottom: 0;
                // padding-top: calc(55px + 39px);
            }
        }
    }
}