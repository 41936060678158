.create-affiliated-page {
    .page-details{
        background-color: var(--white);
        padding: 29px 20px;
    }
}

.booking-container {
   
    .infinite-scroll-component {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
      gap: 20px;
      overflow: visible !important;
      position: relative;

      @media screen and (min-width: 1200px){
        .loader-booking-grid{
          left: "50%" !important;
          transform: translate(50%, -50%)
        }
      }
      @media screen and (max-width: 1200px){
        .loader-booking-grid{
          left: "50%" !important;
          transform: translate(50%, -50%)
        }
      }
    }

    .loader-booking-grid {
      // position: absolute !important; 
      // left: 50% !important;
      // top: 50% !important;
      transform: translate-y(-50%) !important;
      // z-index: 1000;
    }
  }
  
  .booking-item {
    width: 100%;
    cursor: pointer;
  }
  
  .booking-list {
    min-width: 500px;
    min-height: 250px;
    padding: 15px;
    border-radius: 20px;
  
    .header-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 0 24px;
    }
  
    .title {
      font-size: 14px;
    }
  
    .names {
      font-size: 15px;
      font-weight: 500;
    }
    .multiLine{
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  
    .bkng-dtils {
      margin-top: 10px;
      background-color: var(--white);
      border-radius: 15px;
      padding: 10px 20px 8px 20px;
      position: relative;
  
      .details-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px 20px;

        .date-frmt{
            font-style: italic;
            font-size: 13px;
        }
      }
    }
  
    .cal-ico {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  .page-details{
    .section-top{
      border: 1px solid var(--sandy-brown);
      border-radius: 8px;
      padding: 14px 20px;
      background: var(--mid-tone-light);
      .left{
        font-weight: 600;
      }
      .club_title_box{
        background-color: #e1c8a7;
        padding: 5px 20px 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        .club_name_affilifated{
          margin: 0;
          font-size: 16px;
          color: var(--white);
        }
      }
    }
    .affiliated_club_listng_main{
      padding: 20px 20px 9px;
      .affiliated_club_wraper{
        .affiliated_club_head{
          font-size: 14px;
          font-weight: 600;
        }
        .search-container {
          position: relative;
          width: 100%;
          .affiliated_search {
            width: 100%;
            // max-width: 300px; 
            padding: 10px 35px 10px 15px;
        
            border: 1px solid #ccc;
            border-radius: 4px; 
        
        
            font-size: 16px;
            font-family: Arial, sans-serif;
            color: #333; 
        
            outline: none;
            box-sizing: border-box;
          }
        
          .affiliated_search:focus {
              border-color: var(--sandy-brown); 
          }
        
          .affiliated_search::placeholder {
              color: #999;
              font-style: italic;
          }
          .clear-button {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            background: transparent;
            border: none;
            font-size: 18px;
            color: #999;
            cursor: pointer;
        }
        
        .clear-button:hover {
            color: #333;
        }
        }
      }
      .listing_clubs{
        border-left: 1px solid #b5b5b5;
        border-right: 1px solid #b5b5b5;
        border-bottom: 1px solid #b5b5b5;
        padding: 25px;
        height: 600px;
        overflow: auto;
        .custom-checkbox {
          display: inline-block;
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          font-size: 16px;
          user-select: none;
      }
      
      .custom-checkbox input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
      }
      
      .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: white;
          border: 2px solid #999;
          border-radius: 4px;
      }
      
      .custom-checkbox input:checked + .checkmark {
          background-color: var(--sandy-brown); 
          border-color: var(--sandy-brown);
      }
      
      .checkmark:after {
          content: "";
          position: absolute;
          display: none;
      }
      
      .custom-checkbox input:checked + .checkmark:after {
          display: block;
      }
      
      .custom-checkbox .checkmark:after {
          left: 6px;
          top: 2px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
      }
      
        .suggested_club_head{
          margin: 0;
          font-size: 16px;
          color: #999;
        }
        .club-container {
          display: grid;
          grid-template-columns: repeat(4, minmax(250px, 1fr)); 
          gap: 18px;
          padding: 20px;
      }
      @media (max-width: 1400px) {
        .club-container {
            grid-template-columns: repeat(3, minmax(250px, 1fr)); /* 3 columns below 1400px */
        }
      }
      
      @media (max-width: 1024px) {
          .club-container {
              grid-template-columns: repeat(2, minmax(250px, 1fr)); /* 2 columns below 1024px */
          }
      }
      
      @media (max-width: 768px) {
          .club-container {
              grid-template-columns: repeat(1, minmax(250px, 1fr)); /* 1 column below 768px */
          }
      }
      
      .club-card {
          background: #f8f5f2;
          padding: 10px 15px;
          border-radius: 12px;
          border: 1px solid #e0e0e0;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
          transition: transform 0.2s ease-in-out, background-color 0.2s ease;
          cursor: pointer;
          min-width: 250px; /* Ensures it adapts to the grid */
          max-width: 100%;
      
          h3 {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 0;
              line-height: 22px;
          }
      
          p {
              font-size: 14px;
              color: #666;
              margin-bottom: 4px;
          }
      
          &:hover {
              transform: scale(1.05);
          }
          
          &.selected-club {
              background-color: var(--sandy-brown, #d9b38c);
              border-color: #c09a73;
              transform: scale(1.05);
              
              h3, p {
                  color: white;
                  font-weight: 500;
              }
          }
      }      
      }
    }
  }