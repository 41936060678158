// ***********HelveticaNeue ************
@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 100;
    src: url('./HelveticaNeue/HelveticaNeueUltraLight.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueUltraLight.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 200;
    src: url('./HelveticaNeue/HelveticaNeueThin.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueThin.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 300;
    src: url('./HelveticaNeue/HelveticaNeueLight.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueLight.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 400;
    src: url('./HelveticaNeue/HelveticaNeueRoman.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueRoman.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 500;
    src: url('./HelveticaNeue/HelveticaNeueMedium.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueMedium.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 600;
    src: url('./HelveticaNeue/HelveticaNeueBold.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueBold.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 700;
    src: url('./HelveticaNeue/HelveticaNeueHeavy.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueHeavy.woff')format("woff");
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 800;
    src: url('./HelveticaNeue/HelveticaNeueBlack.woff2')format("woff2"),
    url('./HelveticaNeue/HelveticaNeueBlack.woff')format("woff");
}
// ***********HelveticaNeue ************

